<template>
  <div class="card">
    <div class="card-content">
      <ValidationObserver ref="form">
        <form @submit.prevent="handleSubmit">
          <header class="mb-2">
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">
                {{ formTitle }}</h2>
              <div @click="$emit('close')">
                <b-icon class="cursor-pointer" icon="close-thick"/>
              </div>
            </div>
            <div>
              <p class="has-text-weight-bold has-text-grey">{{ selectedFarm.name }}
                <span v-if="selectedAccount">- {{ selectedAccount.name }}</span></p>
            </div>
          </header>
          <section class="my-2">
            <ValidationProvider name="Nama" rules="required" v-slot="{ errors }"
                                v-if="(selectedAccount ? selectedAccount.end_date : false) || action !== 'close'">
              <b-field label="Nama" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="text" id="name" v-model="form.name" placeholder="Input Nama"></b-input>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Tanggal Mulai" rules="required" v-slot="{ errors }">
              <b-field label="Tanggal Mulai" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-datepicker
                    placeholder="Pilih Tanggal"
                    icon="calendar-text"
                    locale="id-ID"
                    v-model="form.created_at">
                </b-datepicker>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Tanggal Selesai" rules="required" v-slot="{ errors }" v-if="action === 'close'">
              <b-field label="Tanggal Selesai" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-datepicker
                    placeholder="Pilih Tanggal"
                    icon="calendar-text"
                    locale="id-ID"
                    v-model="form.end_date">
                </b-datepicker>
              </b-field>
            </ValidationProvider>
          </section>

          <footer class="has-text-right">
            <b-button type="is-primary is-gradient" native-type="submit">Simpan</b-button>
          </footer>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: "FinanceAccountForm",
  props: {
    selectedAccount: Object,
    action: String,
  },
  data: () => {
    return {
      form: {},
    }
  },
  computed: {
    ...mapState('farm', [
      'selectedFarm',
    ]),

    formTitle() {
      switch (this.action) {
        case 'add':
          return this.$t('finance.add_account')
        case 'close':
          return this.$t('finance.close_account')
        case 'edit':
          return `${this.$t('edit')} ${this.$t('finance.account')}`
      }

      return ''
    },
  },
  created() {
    if (this.selectedAccount)
      this.form = {
        ...this.selectedAccount,
        created_at: new Date(this.selectedAccount.created_at),
        end_date: this.selectedAccount.end_date ? new Date(this.selectedAccount.end_date) : null,
      }
  },
  methods: {
    ...mapActions('finance', [
      'createAccount',
      'updateAccount',
    ]),

    handleSubmit() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.$loading()
          if (this.selectedAccount) {
            this.updateAccount({
              ...this.form,
              created_at: this.$timestamp(this.form.created_at),
              end_date: this.form.end_date ? this.$timestamp(this.form.end_date) : 0,
            }).then(() => {
              this.$emit('submit')
              this.$emit('close')
            }).finally(() => {
              this.$loading(false)
            })
          } else {
            this.createAccount({
              farm_id: this.selectedFarm.farm_id,
              name: this.form.name,
              created_at: this.$timestamp(this.form.created_at),
            }).then(() => {
              this.$emit('submit')
              this.$emit('close')
            }).finally(() => {
              this.$loading(false)
            })
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 600px !important;
  max-width: 100%;
}
</style>
